import React, { memo } from 'react';
import PropTypes from 'prop-types';
import Typography from '@pedidosya/web-fenix/atoms/Typography';
import truncate from 'lodash/truncate';
import { PriceSection, Container, UnitMeasure } from './ContentGroceries.style';

const ContentGroceries = memo(function ContentGroceries({
  name,
  originalPrice,
  finalPrice,
  showOriginalPrice,
  size,
  priceUnit,
}) {
  return (
    <Container>
      <PriceSection>
        {showOriginalPrice && (
          <Typography token="font-body-midcontrast-strikethrough-small" color="text-color-tertiary">
            {originalPrice}
          </Typography>
        )}
        <Typography token="font-body-midcontrast-sentence-large" color="text-color-primary">
          {finalPrice}
        </Typography>
      </PriceSection>
      <Typography color="text-color-primary" token="font-body-midcontrast-sentence-small">
        {truncate(name, { length: 18, separator: '' })}
      </Typography>
      <UnitMeasure>
        <Typography color="text-color-primary" token="font-label-highercontrast-small">
          {size}
        </Typography>
        <Typography token="font-body-highcontrast-sentence-small" color="text-color-secondary">
          ·
        </Typography>
        <Typography color="text-color-tertiary" token="font-label-highercontrast-small">
          {priceUnit}
        </Typography>
      </UnitMeasure>
    </Container>
  );
});

ContentGroceries.defaultProps = {
  name: '',
  originalPrice: '',
  finalPrice: '',
  showOriginalPrice: false,
};
ContentGroceries.propTypes = {
  name: PropTypes.string,
  originalPrice: PropTypes.string,
  finalPrice: PropTypes.string,
  showOriginalPrice: PropTypes.bool,
};

export { ContentGroceries };
