import React, { memo } from 'react';
import styled, { keyframes } from 'styled-components';
import PropTypes from 'prop-types';

const SHAPES = {
  CIRCLE: 'circle',
  SQUARE: 'square',
};

const animation = keyframes`
   0% {
     background-position: -468px 0;
   }
   100% {
     background-position: 468px 0;
   }
`;
const Shine = styled.div`
  border-radius: ${(props) => (props.shape === SHAPES.CIRCLE ? '100%' : '6px')};
  height: ${(props) => props.height || '100%'};
  width: ${(props) => props.width || '100%'};
  background: #f6f7f8;
  background-image: linear-gradient(to right, #f6f7f8 0%, #edeef1 20%, #f6f7f8 40%, #f6f7f8 100%);
  background-repeat: no-repeat;
  background-size: 800px 104px;
  display: inline-block;
  position: relative;
  -webkit-animation-duration: 1s;
  -webkit-animation-fill-mode: forwards;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-name: ${animation};
  -webkit-animation-timing-function: linear;
`;

const ImageSkeleton = memo(({ shape, height, width, ...props }) => {
  return <Shine height={height} width={width} shape={shape} {...props} />;
});

ImageSkeleton.propTypes = {
  shape: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
};

ImageSkeleton.defaultProps = { shape: SHAPES.CIRCLE, height: '100%', width: '100%' };

export { ImageSkeleton };
