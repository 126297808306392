import React from 'react';
import PropTypes from 'prop-types';
import { format } from '@pedidosya/currency-formatter';
import isFunction from 'lodash/isFunction';

const NumberFormatter = ({ amount, children, country }) => {
  const formatedPrice = format(parseFloat(amount), country);

  if (isFunction(children)) {
    return children(formatedPrice?.price);
  }

  return <>{formatedPrice?.price}</>;
};

NumberFormatter.defaultProps = {
  amount: 0,
  country: '',
};

NumberFormatter.propTypes = {
  amount: PropTypes.number,
  country: PropTypes.string,
};

const Formatter = (amount, country) => {
  const formatedPrice = format(parseFloat(amount), country);
  return formatedPrice?.price;
};

export { NumberFormatter, Formatter };
