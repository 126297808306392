import React from 'react';
import styled from 'styled-components';
import Typography from '@pedidosya/web-fenix/atoms/Typography';

const Title = styled(Typography)`
  margin-top: ${(props) => (props.mTop ? `${props.mTop}px` : 0)};
  margin-right: 0;
  margin-left: 0;
  margin-bottom: ${(props) => (props.mBottom ? `${props.mBottom}px` : 0)};
  padding: 0;
`;

const ProductContainer = styled.div`
  margin-bottom: 10px;
`;

const DeliveryInfoContainer = styled.div`
  margin-bottom: ${(props) => props.theme.space('space-08')};
`;

export { Title, ProductContainer, DeliveryInfoContainer };
