/**
 * DeliveryInfo
 *
 * @description Component for show how many minute will take to delivery a order by an vendor
 * therefore how is the delivery cost associate to an order. This component will show
 * in the first section of cross selling.
 */
import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import Typography from '@pedidosya/web-fenix/atoms/Typography';
import Translation from './DeliveryInfo.translation';
import { Formatter } from '@app/components/NumberFormatter';
import { useIntl } from 'react-intl';
import { Container } from './DeliveryInfo.style';
import { ImageLoader } from '@app/components/ImageLoader';
import { Constants } from '@app/config/constants';

const DeliveryInfo = memo(function DeliveryInfo({
  time,
  amount,
  discountAmount,
  hasPlus,
  country,
}) {
  const { formatMessage } = useIntl();
  const amountPrice = Formatter(amount, country);
  const discountAmountPrice = Formatter(discountAmount, country);
  const hasDiscount = useMemo(() => {
    return discountAmount && parseFloat(discountAmount) > 0.0;
  }, [discountAmount]);
  const hasFreeDelivery = useMemo(() => {
    return amount === discountAmount;
  }, [amount, discountAmount]);

  return (
    <Container space="space-02">
      <Typography token="font-body-midcontrast-sentence-small" color="text-color-primary">
        {time}
      </Typography>
      <Typography token="font-body-highcontrast-sentence-small" color="text-color-secondary">
        ·
      </Typography>
      <Container space="space-02">
        {hasPlus && (
          <ImageLoader src={`${Constants.PEYA_PLUS_ICON}?width=16&dpi=2`} width={16} height={16} />
        )}
        <Typography token="font-body-midcontrast-sentence-small" color="text-color-primary">
          {hasFreeDelivery
            ? formatMessage(Translation.delivery_label_free)
            : formatMessage(Translation.delivery_label)}
        </Typography>
        {hasDiscount && !hasFreeDelivery ? (
          <Typography
            token="font-body-midcontrast-sentence-small"
            color="text-color-primary"
            data-testid="discount"
          >
            {discountAmountPrice}
          </Typography>
        ) : (
          <></>
        )}
        {!hasFreeDelivery && (
          <Typography
            token={
              hasDiscount
                ? 'font-body-midcontrast-strikethrough-small'
                : 'font-body-midcontrast-sentence-small'
            }
            color={hasDiscount ? 'text-color-tertiary' : 'text-color-primary'}
            data-testid="amount"
          >
            {amountPrice}
          </Typography>
        )}
      </Container>
    </Container>
  );
});

DeliveryInfo.defaultProps = {
  time: '',
  amount: 0,
  discountAmount: 0,
  hasPlus: false,
  country: '',
};

DeliveryInfo.propTypes = {
  time: PropTypes.string,
  amount: PropTypes.number,
  discountAmount: PropTypes.number,
  hasPlus: PropTypes.bool,
  country: PropTypes.string,
};

export { DeliveryInfo };
