import React from 'react';
import { useWindowSize } from '@app/hooks/useWindowSize';
import { Container, Item, Title, ButtonAction, TitleContainer } from './ErrorPage.style';
import { useWebViewEvents } from '@app/hooks/useWebViewEvents';
import { WebViewEvents } from '@app/config/webViewEvents';
import Button from '@pedidosya/web-fenix/atoms/Button';
import Image from '@pedidosya/web-fenix/foundations/Image';
import ErrorServer from '@app/assets/images/error_server.svg';
import useIsWebView from '@app/hooks/useIsWebView';

const ErrorPage = () => {
  const { height } = useWindowSize();
  const { fire } = useWebViewEvents();
  const isWebview = useIsWebView();

  const reload = () => {
    if (isWebview) {
      fire(WebViewEvents.RELOAD);
    } else {
      if (typeof window !== 'undefined') {
        window.location.reload();
      }
    }
  };

  return (
    <Container height={height - 32} isWebview={isWebview}>
      <Item>
        <Image src={ErrorServer} alt="error_server" width={32} height={58} />
        <TitleContainer>
          <Title
            component="h3"
            token="font-subtitle-highcontrast-sentence-large"
            color="text-color-primary"
          >
            Lo sentimos, algo salió mal
          </Title>
        </TitleContainer>
        <ButtonAction>
          <Button label="Reintentar" hierarchy="primary" type="button" onClick={reload} />
        </ButtonAction>
      </Item>
    </Container>
  );
};

export { ErrorPage };
