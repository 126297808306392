import { defineMessages } from 'react-intl';

const messages = defineMessages({
  delivery_label: {
    id: 'OM_page_offer_delivery_label',
    defaultMessage: 'Envío',
  },
  delivery_label_free: {
    id: 'OM_page_offer_delivery_label_free',
    defaultMessage: 'Envío gratis',
  },
});

export default messages;
