class EventListener {
  constructor() {}

  subscribe(...args) {
    let subscribers = [];
    args.forEach((subscriptor) => {
      subscribers.push(subscriptor);
    });
    return [
      (context) => {
        subscribers.forEach((subscriptor) => {
          if (subscriptor) {
            return subscriptor(context);
          }
        });
      },
      () => {
        subscribers = [];
      },
    ];
  }
}

const Event = new EventListener();

export { Event as EventListener };
