import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { RouterApp } from '@app/components/Router';
import { OrderOfferPage } from '@app/pages/orderOffer';
import { usePublicEnv } from '@app/contexts/PublicEnv';
import { HttpClient } from '@app/utils/http';

const AppLoader = memo(function AppLoader({ orderId, flow, mock, pWebView }) {
  const { isParcel } = usePublicEnv();

  if (isParcel === true || isParcel === undefined) {
    return <OrderOfferPage orderId={orderId} flow={flow} mock={mock} />;
  }

  return <RouterApp />;
});

AppLoader.defaultProps = {
  orderId: '',
  flow: 'orderStatus',
  mock: false,
  pWebView: false,
};

AppLoader.propTypes = {
  orderId: PropTypes.string,
  flow: PropTypes.string,
  mock: PropTypes.bool,
  pWebView: PropTypes.bool,
};

export { AppLoader };
