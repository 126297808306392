import React from 'react';
import styled from 'styled-components';

const SkeletonContainer = styled.div`
  flex-shrink: 0;
  min-width: 135px;
  margin-right: 12px;
  &:last-child {
    margin-right: 0px;
  }
`;

export { SkeletonContainer };
