import { defineMessages } from 'react-intl';

const messages = defineMessages({
  offer_title: {
    id: 'OM_page_offer_title',
    defaultMessage: 'Recíbelos en un nuevo pedido',
  },
  offer_market_title: {
    id: 'OM_page_offer_market_title',
    defaultMessage: 'Aprovecha estas opciones',
  },
  offer_market_view_all: {
    id: 'OM_page_offer_market_view_all',
    defaultMessage: 'Ver todos',
  },
});

export default messages;
