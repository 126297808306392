import React from 'react';
import PropTypes from 'prop-types';
import fenixTheme from '@pedidosya/web-fenix/theme';
import ThemeProvider from '@pedidosya/web-fenix/theme/ThemeProvider';
import FenixFonts from '@pedidosya/web-fenix/theme/FenixFonts';
import theme from '@appscore/web-components/theme';
import QueryClient from '@app/utils/query_client';
import { SentryProvider } from '@app/contexts/SentryProvider';
import { createIntlProvider, getMessages } from './contexts/IntlProvider';
import {
  usePublicEnv,
  PublicEnvProvider,
  publicEnvShape,
  getPublicEnv,
} from './contexts/PublicEnv';
import { QueryClientProvider } from 'react-query';
import { LocalStyle } from '@app/components/LocalStyle';
import { LinkProvider } from '@app/contexts/LinkProvider';
import { AppLoader } from '@app/components/AppLoader';
import { TrackerProvider } from '@app/contexts/TrackerProvider';

const IntlProvider = (props) => {
  const { locale } = usePublicEnv();
  return createIntlProvider(locale, getMessages)(props);
};

const Root = ({ context, messages, orderId, flow, tracker, mock, pWebView, trackerConfig }) => {
  return (
    <PublicEnvProvider context={context} getPublicEnv={getPublicEnv} pWebView={pWebView}>
      <SentryProvider>
        <ThemeProvider theme={{ ...theme, ...fenixTheme }}>
          <>
            <FenixFonts />
            <LocalStyle />
            <IntlProvider initialMessages={messages}>
              <TrackerProvider tracker={tracker} config={trackerConfig}>
                <QueryClientProvider client={QueryClient}>
                  <LinkProvider>
                    <AppLoader orderId={orderId} flow={flow} mock={mock} pWebView={pWebView} />
                  </LinkProvider>
                </QueryClientProvider>
              </TrackerProvider>
            </IntlProvider>
          </>
        </ThemeProvider>
      </SentryProvider>
    </PublicEnvProvider>
  );
};

const ssrProps = typeof window !== 'undefined' ? window.__INITIAL_DATA__ : {};
Root.defaultProps = { ...ssrProps };

Root.propTypes = {
  context: PropTypes.shape(publicEnvShape),
  messages: PropTypes.shape({}),
  name: PropTypes.string,
  orderId: PropTypes.string,
  flow: PropTypes.string,
  mock: PropTypes.bool,
  pWebView: PropTypes.bool,
  trackerConfig: PropTypes.object,
  tracker: PropTypes.object,
};

export default Root;
