import React from 'react';
import styled from 'styled-components';
import Typography from '@pedidosya/web-fenix/atoms/Typography';

const Container = styled.div`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  align-content: center;
  min-height: ${(props) => (props.isWebview ? `${props.height}px` : 'auto')};
`;

const Item = styled.div`
  flex-grow: 1;
  text-align: center;
  & > picture {
    display: inline-block;
  }
`;

const Title = styled(Typography)`
  margin-top: ${(props) => props.theme.space('space-12')};
  margin-bottom: ${(props) => props.theme.space('space-12')};
  display: inline-block;
  width: 120px;
`;

const TitleContainer = styled.div`
  width: 100%;
  text-align: center;
  display: block;
`;

const ButtonAction = styled.div`
  text-align: center;
  & > div {
    display: inline-block;
  }
`;

export { Container, Item, Title, ButtonAction, TitleContainer };
