import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import Swiper from '@appscore/web-components/Swiper';
import { MarketSection } from '@app/components/OrderOffer/MarketSection';
import { MarketContainer, ViewAllContainer } from './MarketList.style';
import { ViewAll } from '@app/components/OrderOffer/ViewAll';
import { Verticals } from '@app/config/verticals';
import { useLinkContext } from '@app/contexts/LinkProvider';
import { ACTIONS } from '@app/utils/deeplink';
import { useWindowSize } from '@app/hooks/useWindowSize';
import { Constants } from '@app/config/constants';
import { useTracker } from '@app/contexts/TrackerProvider';

const MarketList = memo(function MarketList({ markets, businessType, url, orderId }) {
  const { redirect } = useLinkContext();
  const { vendorClick } = useTracker();
  const { width } = useWindowSize();
  const city = useMemo(() => {
    if (!url) {
      return '';
    }
    const regex = new RegExp('/([A-Za-z]+)/([a-zA-Z]+)/([a-zA-z0-9-]+)$', 'gi');
    if (regex.test(url)) {
      return url.split('/')[2];
    }
    return url;
  }, [url]);

  const goToShopList = (businessType) => {
    redirect(ACTIONS.VERTICAL, { vertical: businessType, city: city });
  };

  const goToShopDetail = (vendorId, url) => {
    vendorClick(Constants.ORIGIN_OS, orderId, vendorId);
    redirect(ACTIONS.SHOPDETAIL, { vendorId, url });
  };

  return (
    <Swiper withScrollButtons={Constants.SHOW_SWIPE_CONTROLS_WINDOW_WIDTH <= width}>
      {markets.map((element) => {
        return (
          <MarketContainer
            key={element?.vendorInformation?.id}
            data-testid="container"
            onClick={() =>
              goToShopDetail(element?.vendorInformation?.id, element?.vendorInformation?.url)
            }
          >
            <MarketSection {...element.vendorInformation} />
          </MarketContainer>
        );
      })}
      <ViewAllContainer>
        <ViewAll size="small" onClick={() => goToShopList(businessType)} />
      </ViewAllContainer>
    </Swiper>
  );
});

MarketList.defaultProps = {
  markets: [],
  businessType: Verticals.GROCERIES,
  url: '',
  orderId: '',
};

MarketList.propTypes = {
  markets: PropTypes.arrayOf(
    PropTypes.shape({
      vendorInformation: PropTypes.object,
      products: PropTypes.array,
    }),
  ),
  businessType: PropTypes.oneOf(Object.values(Verticals)),
  url: PropTypes.string,
  orderId: PropTypes.string,
};

export { MarketList };
