/**
 * NotFoundOffer
 *
 * @description Component to show a message when the service does not return offers
 */
import React, { useEffect, memo } from 'react';
import PropTypes from 'prop-types';
import Image from '@pedidosya/web-fenix/foundations/Image';
import NotFoundOfferImage from '@app/assets/images/not_found_offer.svg';
import Translations from './NotFoundOffer.translation';
import useIsWebView from '@app/hooks/useIsWebView';
import { useTracker } from '@app/contexts/TrackerProvider';
import { useWindowSize } from '@app/hooks/useWindowSize';
import { Container, Title, TitleContainer } from './NotFoundOffer.style';
import { useIntl } from 'react-intl';
import { Constants } from '@app/config/constants';

const NotFoundOffer = memo(function NotFoundOffer({ orderId }) {
  const { height } = useWindowSize();
  const { formatMessage } = useIntl();
  const { emptyOffer } = useTracker();
  const isWebview = useIsWebView();

  useEffect(() => {
    emptyOffer(Constants.ORIGIN_OS, orderId);
  }, [emptyOffer, orderId]);

  return (
    <Container height={height} isWebview={isWebview}>
      <Image alt="not_found_offer" src={NotFoundOfferImage} width={57} height={45} />
      <TitleContainer>
        <Title
          component="h3"
          token="font-subtitle-highcontrast-sentence-large"
          color="text-color-primary"
        >
          {formatMessage(Translations.not_found_offer_title)}
        </Title>
      </TitleContainer>
    </Container>
  );
});

NotFoundOffer.defaultProps = {
  orderId: '',
};

NotFoundOffer.propTypes = {
  orderId: PropTypes.string,
};

export { NotFoundOffer };
