import { defineMessages } from 'react-intl';

const messages = defineMessages({
  not_found_offer_title: {
    id: 'OM_page_not_found_offer_title',
    defaultMessage: 'En este momento no podemos ofrecerte otros productos.',
  },
});

export default messages;
