import React from 'react';
import ReactDOM from 'react-dom';
import singleSpaReact from 'single-spa-react';
import beforeHydratation from '@app/utils/beforeHydratation/beforeHydratation';

import pkg from '../package.json';
import { ErrorBoundary } from '@app/components/ErrorBoundary';

const rootId = `single-spa-application:${pkg.name}`;
let domElement = document.getElementById(rootId);

if (!document.body.contains(domElement)) {
  domElement = document.createElement('section');
  domElement.id = rootId;
  document.body.appendChild(domElement);
}

const lifecycles = singleSpaReact({
  domElementGetter: () => domElement,
  React,
  ReactDOM,
  renderType: () => (domElement.hasChildNodes() ? 'hydrate' : 'render'),
  loadRootComponent: async (props) => {
    const tracker = await props.trackerPromise;
    const fwf = props.fwfPromise ? props.fwfPromise : null;
    const orderId = props?.orderId;
    const flow = props?.flow;
    const mock = props?.mock;
    const pWebView = props?.pWebView;
    const { App } = await beforeHydratation({ tracker, fwf, orderId, flow, mock, pWebView });
    return App;
  },
  errorBoundary: (error) => <ErrorBoundary error={error} />,
});

export const { bootstrap, mount, unmount } = lifecycles;
