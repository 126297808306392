import React, { useState } from 'react';
import { getItem, setItem } from '@app/utils/storage';
import { Constants } from '@app/config/constants';
import Switch from '@pedidosya/web-fenix/atoms/Switch';
import styled from 'styled-components';

const MockContainer = styled.div`
  display: flex;
  padding-right: 16px;
  & > span:first-child {
    margin-top: 4px;
    margin-right: 8px;
  }
`;

const getInitialValue = () => {
  try {
    const value = getItem(Constants.MOCK_FLAG);
    return value && value === 'false' ? false : true || true;
  } catch (e) {}
};

const MockSwitch = () => {
  const [state, setState] = useState(getInitialValue());

  const onChange = () => {
    setState(!state);
    setItem(Constants.MOCK_FLAG, !state);
    window.location.reload();
  };

  return (
    <MockContainer>
      <span>Mock</span>
      <Switch selected={state} onClick={onChange} />
    </MockContainer>
  );
};

export { MockSwitch };
