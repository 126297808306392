import { React } from 'react';
import styled from 'styled-components';

const PriceSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  gap: ${(props) => props.theme.space('space-04')};
`;

export { PriceSection, Container };
