import { React } from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
  cursor: pointer;
`;

const HeaderSection = styled.div`
  position: relative;
`;

const TagContainer = styled.div`
  position: absolute;
  bottom: ${(props) => props.theme.space('space-05')};
  left: ${(props) => (props.alignLeft ? '0px' : props.theme.space('space-06'))};
  width: 100%;
`;

const ContentSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: ${(props) => props.theme.space('space-04')};
  padding: ${(props) => props.theme.space('space-06')};
  flex-grow: 1;
`;

export { Container, HeaderSection, ContentSection, TagContainer };
