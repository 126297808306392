import { Urls } from '@app/config/urls';
import { HttpClient } from '@app/utils/http';

const OrderOfferRepository = {
  /**
   * getOffer
   *
   * @description Method for call backend to return order offer
   * @param {string} orderId - Order Id
   * @param {string} flow - Origin request
   * @param {string} mock - Request data mock or not
   * @param {Object} signal - Object for cancel request when is required
   * @returns {Promise<Object>}
   */
  getOffer: (orderId, flow, mock, signal) => {
    const http = HttpClient.getInstance();
    const url = Urls.ORDER_OFFER.replace(':orderId', orderId);
    const finalUrl = `${url}?flow=${flow}&mock=${mock}`;

    return http.get(finalUrl, { signal });
  },
};

export { OrderOfferRepository };
