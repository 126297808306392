import React, { memo, useMemo, useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import GTMEvents from '@app/config/gtmEvents';
import { TrackerContext } from './context';
import { usePublicEnv } from '@app/contexts/PublicEnv';
import { useIntl } from 'react-intl';
import { initialize as initializeTracker, track } from '@pedidosya/web-native-bridges/tracker';
import isEmpty from 'lodash/isEmpty';

const TrackerProvider = memo(function TrackerProvider({ children, tracker, config }) {
  const { userId } = usePublicEnv();
  const { formatMessage } = useIntl();
  const [trackerInstance, setTracker] = useState(tracker);

  const loadTracker = useCallback(async () => {
    try {
      await initializeTracker(config);
      setTracker({ track });
    } catch (e) {
      return null;
    }
  }, [config]);

  useEffect(() => {
    if (!isEmpty(trackerInstance)) {
      return;
    }

    loadTracker();
  }, [trackerInstance, loadTracker]);

  /**
   * productClick
   *
   * @description Function to fire an event to gtm to track
   * when user make click over product
   * @param {string} origin - Origin request
   * @param {string} orderId - Order id
   * @param {string} productId - Product id
   * @param {string} vendorId - Vendor id
   */
  const productClick = (origin, orderId, productId, vendorId) => {
    trackerInstance?.track(GTMEvents.ProductClicked, {
      userId,
      orderId,
      origin,
      productId: productId,
      vendorId: vendorId,
    });
  };

  /**
   * vendorClick
   *
   * @description Function to fire an event to gtm to track
   * when user make click over vendor card
   * @param {string} origin - Origin request
   * @param {string} orderId - Order id
   * @param {string} productId - Product id
   * @param {string} vendorId - Vendor id
   */
  const vendorClick = (origin, orderId, vendorId) => {
    trackerInstance?.track(GTMEvents.VendorClicked, {
      userId,
      orderId,
      origin,
      vendorId: vendorId,
    });
  };
  /**
   * emptyOffer
   *
   * @description Function to fire an event to gtm to track
   * when user view empty offer error
   * @param {string} origin - Origin request
   * @param {string} orderId - Order id
   */
  const emptyOffer = (origin, orderId) => {
    trackerInstance?.track(GTMEvents.EmptyOffer, {
      userId,
      orderId,
      origin,
    });
  };

  const value = useMemo(
    () => ({
      gtmTrack: trackerInstance?.track,
      productClick,
      vendorClick,
      emptyOffer,
    }),
    [trackerInstance],
  );

  return <TrackerContext.Provider value={value}>{children}</TrackerContext.Provider>;
});

TrackerProvider.propTypes = {
  children: PropTypes.node.isRequired,
  tracker: PropTypes.object,
  config: PropTypes.object,
};

export { TrackerProvider };
