import React, { memo } from 'react';
import PropTypes from 'prop-types';
import CardContainer from '@pedidosya/web-fenix/molecules/CardContainer';
import truncate from 'lodash/truncate';
import { Container, VendorName } from './MarketSection.style';
import { Constants } from '@app/config/constants';
import { ImageLoader } from '@app/components/ImageLoader';
import { DeliveryInfo } from '@app/components/OrderOffer/DeliveryInfo';

const MarketSection = memo(function MarketSection({ name, delivery, logo, countryCode }) {
  const vendorImage = logo ? `${Constants.BASE_URL_VENDOR_IMAGE}/${logo}?width=40` : '';
  return (
    <CardContainer withShadow={false}>
      <Container>
        <ImageLoader
          src={vendorImage}
          width={40}
          height={40}
          fallback={Constants.FALLBACK_PRODUCT_ICON}
        />
        <div>
          <VendorName
            component="h4"
            token="font-subtitle-highcontrast-sentence-medium"
            color="text-color-primary"
          >
            {truncate(name, { length: 25, separator: ' ' })}
          </VendorName>
          <DeliveryInfo
            country={countryCode}
            amount={delivery?.amount}
            hasPlus={delivery?.hasPlus}
            discountAmount={delivery?.discountAmount}
            time={delivery?.time}
          />
        </div>
      </Container>
    </CardContainer>
  );
});

MarketSection.defaultProps = {
  name: '',
  id: '',
  url: '',
  logo: '',
  countryCode: '',
  delivery: {},
};

MarketSection.propTypes = {
  name: PropTypes.string,
  id: PropTypes.string,
  url: PropTypes.string,
  logo: PropTypes.string,
  countryCode: PropTypes.string,
  delivery: PropTypes.shape({
    time: PropTypes.string,
    amount: PropTypes.number,
  }),
};

export { MarketSection };
