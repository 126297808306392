import axios from 'axios';
import { Urls } from '@app/config/urls';

const AxiosInstance = (function () {
  let instance = null;
  function Singleton() {
    if (instance) {
      return instance;
    }
    instance = this;
  }
  Singleton.getInstance = function (basePath) {
    if (!instance) {
      instance = axios.create({
        baseURL: basePath ? `${basePath}${Urls.BASE_HTTP}` : Urls.BASE_HTTP,
        timeout: 30000,
        headers: {
          'Content-Type': 'application/json',
        },
      });
    }
    return instance;
  };
  return Singleton;
})();

export { AxiosInstance as HttpClient };
