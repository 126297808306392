import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import CardContainer from '@pedidosya/web-fenix/molecules/CardContainer';
import Tag from '@pedidosya/web-fenix/atoms/Tag';
import { Formatter } from '@app/components/NumberFormatter';
import { Constants } from '@app/config/constants';
import { Verticals } from '@app/config/verticals';
import { Container, HeaderSection, ContentSection, TagContainer } from './ProductCard.style';
import { ImageLoader } from '@app/components/ImageLoader';
import { ContentFood } from './ContentFood';
import { ContentGroceries } from './ContentGroceries';

const ProductCard = memo(function ProductCard({
  name,
  description,
  image,
  price,
  country,
  size,
  businessType,
}) {
  const finalPrice = Formatter(price?.finalPrice, country);
  const originalPrice = Formatter(price?.originalPrice, country);
  const productImage = image ? `${Constants.BASE_URL_PRODUCTS_IMAGE}/${image}?width=136` : '';
  const showdiscount = useMemo(() => {
    return (
      price?.originalPrice &&
      parseFloat(price?.originalPrice) > 0.0 &&
      parseFloat(price?.finalPrice) !== parseFloat(price?.originalPrice)
    );
  }, [price.originalPrice, price?.finalPrice]);

  const showTag = useMemo(() => {
    return price?.discount?.percentage && parseFloat(price?.discount?.percentage) > 0.0;
  }, [price?.discount?.percentage]);

  return (
    <CardContainer>
      <Container>
        <HeaderSection>
          <ImageLoader
            src={productImage}
            width={136}
            height={92}
            fallback={Constants.FALLBACK_PRODUCT_ICON}
            border={0}
          />
          {showTag ? (
            <TagContainer alignLeft={Verticals.GROCERIES == businessType}>
              <Tag category="deals-and-discounts" label={`${price?.discount?.percentage}% off`} />
            </TagContainer>
          ) : (
            <></>
          )}
        </HeaderSection>
        <ContentSection>
          {Verticals.GROCERIES == businessType && (
            <ContentGroceries
              finalPrice={finalPrice}
              originalPrice={originalPrice}
              name={name}
              showOriginalPrice={showdiscount}
              size={size}
              priceUnit={price?.pricePerMeasurementUnit}
            />
          )}
          {Verticals.GROCERIES != businessType && (
            <ContentFood
              finalPrice={finalPrice}
              originalPrice={originalPrice}
              name={name}
              showOriginalPrice={showdiscount}
            />
          )}
        </ContentSection>
      </Container>
    </CardContainer>
  );
});

ProductCard.defaultProps = {
  id: '',
  name: '',
  description: '',
  image: '',
  price: {},
  country: '',
  size: '',
  businessType: Verticals.GROCERIES,
};

ProductCard.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  description: PropTypes.string,
  image: PropTypes.string,
  price: PropTypes.shape({
    originalPrice: PropTypes.number,
    finalPrice: PropTypes.number.isRequired,
    pricePerMeasurementUnit: PropTypes.number,
    discount: PropTypes.shape({
      amount: PropTypes.number,
      percentage: PropTypes.number,
    }),
  }).isRequired,
  country: PropTypes.string,
  size: PropTypes.string,
  businessType: PropTypes.oneOf(Object.values(Verticals)),
};

export { ProductCard };
