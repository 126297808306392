import { React } from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: ${(props) => props.theme.space('space-04')};
`;

const PriceSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

const UnitMeasure = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${(props) => props.theme.space('space-02')};
  align-items: center;
`;

export { PriceSection, Container, UnitMeasure };
