import React from 'react';
import { getOffer } from '@app/services/orderOfferService';
import { useQuery } from 'react-query';
import { usePublicEnv } from '@app/contexts/PublicEnv';

const useOrderOffer = (orderIdParam, flow, mock) => {
  const { data, isLoading, error } = useQuery(
    ['orderOffers', orderIdParam],
    async ({ signal }) => getOffer(orderIdParam, flow, mock, signal),
    {
      retry: 2,
    },
  );

  return {
    data,
    isLoading,
    error,
  };
};

export { useOrderOffer };
