import React from 'react';
import styled from 'styled-components';

const ImageContainer = styled.div`
  width: ${(props) => `${props.width}px`};
  height: ${(props) => `${props.height}px`};
  border: ${(props) => (props.justRadiusLeft || !props.border ? '0px' : '1px solid #dbdade')};
  background-image: url(${(props) => props.src});
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  opacity: ${(props) => (props.state === 'enable' ? 1 : 0.3)};
  border-radius: ${(props) =>
    props.justRadiusLeft
      ? `${props.borderRadius}px 0px 0px ${props.borderRadius}px`
      : `${props.borderRadius}px`};
  -webkit-border-radius: ${(props) =>
    props.justRadiusLeft
      ? `${props.borderRadius}px 0px 0px ${props.borderRadius}px`
      : `${props.borderRadius}px`};
  -moz-border-radius: ${(props) =>
    props.justRadiusLeft
      ? `${props.borderRadius}px 0px 0px ${props.borderRadius}px`
      : `${props.borderRadius}px`};
`;

export { ImageContainer };
