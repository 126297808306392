import React from 'react';
import styled from 'styled-components';

const MarketContainer = styled.div`
  flex-shrink: 0;
  min-width: 284px;
  margin-right: ${(props) => props.theme.space('space-06')};
  & > div {
    height: auto;
    width: auto;
  }
  &:last-child {
    margin-right: 0px;
  }
`;

const ViewAllContainer = styled.div`
  flex-shrink: 0;
  width: 135px;
  & > div {
    height: 64px;
  }
`;

export { MarketContainer, ViewAllContainer };
