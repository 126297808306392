import React from 'react';
import styled from 'styled-components';

const Product = styled.div`
  margin-right: ${(props) => props.theme.space('space-06')};
  flex-wrap: wrap;
  display: flex;
  &:last-child {
    margin-right: 0px;
  }
  & > div {
    height: auto;
    width: 136px;
  }
`;

const ViewAllContainer = styled.div`
  flex-wrap: wrap;
  display: flex;
  & > div {
    height: auto;
    width: 136px;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: ${(props) => props.theme.space('space-07')};
`;

export { Container, Product, ViewAllContainer };
