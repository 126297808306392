import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useOrderOffer } from './useOrderOffer';
import { OrderOffer } from '@app/components/OrderOffer';
import { Container } from '@app/components/Container';
import { ErrorPage } from '@app/components/ErrorPage';
import { PageLoading } from '@app/components/PageLoading';
import { MockSwitch } from '@app/components/MockSwitch';

const OrderOfferPage = memo(function OrderOfferPage({ orderId, flow, mock }) {
  const { data, isLoading, error } = useOrderOffer(orderId, flow, mock);

  if (isLoading) {
    return <PageLoading />;
  }

  if (error) {
    return <ErrorPage />;
  }

  return (
    <Container pLeft="0" pRight="1" pTop="0">
      {/true/i.test(mock) && <MockSwitch />}
      <OrderOffer offers={data} orderId={orderId} />
    </Container>
  );
});

OrderOfferPage.defaultProps = {
  orderId: '',
  flow: 'orderStatus',
  mock: false,
};

OrderOfferPage.propTypes = {
  flow: PropTypes.string,
  orderId: PropTypes.string,
  mock: PropTypes.bool,
};

export { OrderOfferPage };
