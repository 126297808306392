import { DEVICESOS, DEVICES } from '@app/config/device';

export const DeepLink = {
  grocery: {
    ios: 'pedidosya://groceries/productConfiguration?productSku={productId}&vendorId={vendorId}&path={path}',
    android:
      'pedidosya://groceries/productConfiguration?productSku={productId}&vendorId={vendorId}&path={path}',
    web: '{url}',
  },
  food: {
    ios: 'pedidosya://food_product_configuration/{vendorId}/products/{productId}?origin=orderStatus&occasion=DELIVERY&is_joker=false&is_most_ordered=false&menu_section=menu&resolver=SHOPDETAIL',
    android:
      'pedidosya://food_product_configuration/{vendorId}/products/{productId}?origin=orderStatus&occasion=DELIVERY&is_joker=false&is_most_ordered=false&menu_section=menu&resolver=SHOPDETAIL',
    web: '{url}',
  },
  shopList: {
    ios: 'pedidosya://shop_detail/{vendorId}',
    android: 'pedidosya://shop_detail/{vendorId}',
    web: '{url}',
  },
  vertical: {
    ios: 'pedidosya://restaurantes?bt={vertical}',
    android: 'pedidosya://restaurantes?bt={vertical}',
    web: '/restaurantes/{city}',
  },
};

export const ACTIONS = {
  GROCERY: 'grocery',
  FOOD: 'food',
  SHOPDETAIL: 'shopList',
  VERTICAL: 'vertical',
};

/**
 * generateDeepLink
 *
 * @description Function for generate deeplink based on os param
 * @param {string} action - Action to generate deep link
 * @param {string} os - OS of device (Ios | AndroidOS)
 * @param {Object} params - Any param to replace in final deeplink
 * @returns {string}
 */
export const generateDeepLink = (action, os, params) => {
  switch (os) {
    case DEVICESOS.ANDROID:
      return replaceAll(DeepLink[action].android, params);
    case DEVICESOS.IOS:
      return replaceAll(DeepLink[action].ios, params);
    default:
      return replaceAll(DeepLink[action].web, params);
  }

  function replaceAll(str, params) {
    let finalStr = str;
    for (const [key, value] of Object.entries(params)) {
      finalStr = finalStr.replace(`{${key}}`, value);
    }
    return finalStr;
  }
};

/**
 * generateLink
 *
 * @description Function for genereate link or deeplink in base to platform
 * @param {string} device - Name of device
 * @param {string} deviceOS - Operative System of device
 * @param {Object} params - Object with parameters to build final urls
 */
export const generateLink = (device, deviceOS, action, params) => {
  if (DEVICES.MOBILE === device || DEVICES.WEBVIEW === device) {
    return generateDeepLink(action, deviceOS, params);
  } else {
    return generateDeepLink(action, DEVICESOS.WEB, params);
  }
};
