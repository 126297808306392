import { OrderOfferRepository } from '@app/repositories/orderOfferRepository';
import orderBy from 'lodash/orderBy';

/**
 * getOffer
 *
 * @description Function to call repository to get order offer
 * @param {string} orderId - Order id
 * @param {string} flow - Origin of request
 * @param {string} mock - Request data mock or not
 * @param {Object} signal - Object to cancel request when is required
 * @returns {Promise<Object>}
 */
export async function getOffer(orderId, flow, mock, signal) {
  const result = await OrderOfferRepository.getOffer(orderId, flow, mock, signal);
  return orderBy(result?.data, ['hierarchy'], ['asc']);
}
