import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { getItem } from '@app/utils/storage';
import { Constants } from '@app/config/constants';
/**
 * config
 */
import { Routes } from '@app/config/routes';
/**
 * pages
 */
import { OrderOfferPage } from '@app/pages/orderOffer';

const RouterApp = () => {
  return (
    <Switch>
      <Route
        exact
        path={Routes.ORDER_OFFER}
        render={(props) => {
          const query = new URLSearchParams(props?.location?.pathname + props?.location?.search);
          return (
            <OrderOfferPage
              orderId={props?.match?.params?.orderId}
              flow={query.get('flow')}
              mock={getItem(Constants.MOCK_FLAG, false) || false}
            />
          );
        }}
      ></Route>
    </Switch>
  );
};

export { RouterApp };
