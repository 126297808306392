import React from 'react';
import { loadableReady } from '@loadable/component';
import App from '@app/root.component';
import { BrowserRouter } from 'react-router-dom';

function beforeHydratation({ tracker, fwf, orderId, flow, mock, pWebView }) {
  return new Promise(async (resolve, reject) => {
    loadableReady(async () => {
      resolve({
        App: function AppWrapper() {
          return (
            <BrowserRouter>
              <App
                tracker={tracker}
                fwf={fwf}
                orderId={orderId}
                flow={flow}
                mock={mock}
                pWebView={pWebView}
              />
            </BrowserRouter>
          );
        },
      });
    }).catch((e) => {
      reject(e);
    });
  });
}

export default beforeHydratation;
