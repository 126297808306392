import React, { memo } from 'react';
import PropTypes from 'prop-types';
import Typography from '@pedidosya/web-fenix/atoms/Typography';
import { PriceSection, Container } from './ContentFood.style';

const ContentFood = memo(function ContentFood({
  name,
  originalPrice,
  finalPrice,
  showOriginalPrice,
}) {
  return (
    <Container>
      <Typography color="text-color-primary" token="font-subtitle-highcontrast-sentence-medium">
        {name}
      </Typography>
      <PriceSection>
        {showOriginalPrice && (
          <Typography token="font-body-midcontrast-strikethrough-small" color="text-color-primary">
            {originalPrice}
          </Typography>
        )}
        <Typography token="font-body-midcontrast-sentence-large" color="text-color-primary">
          {finalPrice}
        </Typography>
      </PriceSection>
    </Container>
  );
});

ContentFood.defaultProps = {
  name: '',
  originalPrice: '',
  finalPrice: '',
  showOriginalPrice: false,
};
ContentFood.propTypes = {
  name: PropTypes.string,
  originalPrice: PropTypes.string,
  finalPrice: PropTypes.string,
  showOriginalPrice: PropTypes.bool,
};

export { ContentFood };
