import React, { memo, useEffect } from 'react';
import PropTypes from 'prop-types';
import Swiper from '@appscore/web-components/Swiper';
import { Product, ViewAllContainer } from './ProductGrid.style';
import { Verticals } from '@app/config/verticals';
import { EventListener } from '@app/utils/eventListener';
import { useProductGridEvents } from './ProductsGrid.events';
import { ProductCard } from '@app/components/OrderOffer/ProductCard';
import { ViewAll } from '@app/components/OrderOffer/ViewAll';
import { useWindowSize } from '@app/hooks/useWindowSize';
import { Constants } from '@app/config/constants';

const ProductsGrid = memo(function ProductsGrid({
  products,
  country,
  businessType,
  vendorId,
  url,
  orderId,
}) {
  const Listeners = useProductGridEvents();
  const { width } = useWindowSize();
  const [dispatch, unregister] = EventListener.subscribe(Listeners[0]);
  const [dispatchDetail, unregisterDetail] = EventListener.subscribe(Listeners[1]);
  const elements = products.sort((a, b) => {
    if (!a) {
      return 1;
    }
    if (!b) {
      return -1;
    }
    if (a === b) {
      return 0;
    }
    return a < b ? -1 : 1;
  });

  useEffect(
    () => () => {
      unregister();
      unregisterDetail();
    },
    [],
  );

  return (
    <Swiper withScrollButtons={Constants.SHOW_SWIPE_CONTROLS_WINDOW_WIDTH <= width}>
      {elements.map((element) => {
        return (
          <Product
            data-testid="product"
            key={element?.id}
            onClick={() =>
              dispatch({
                productId: element?.id,
                businessType: businessType,
                vendorId: vendorId,
                orderId,
                url,
              })
            }
          >
            <ProductCard {...element} country={country} businessType={businessType} />
          </Product>
        );
      })}
      <ViewAllContainer>
        <ViewAll onClick={() => dispatchDetail({ vendorId: vendorId, url })} />
      </ViewAllContainer>
    </Swiper>
  );
});

ProductsGrid.defaultProps = {
  products: [],
  country: '',
  businessType: '',
  vendorId: '',
  url: '',
  orderId: '',
};

ProductsGrid.propTypes = {
  products: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      description: PropTypes.string,
      image: PropTypes.string,
      raiting: PropTypes.number,
      price: PropTypes.shape({
        finalPrice: PropTypes.number.isRequired,
        originalPrice: PropTypes.number.isRequired,
        discount: PropTypes.shape({
          amount: PropTypes.number,
          percentage: PropTypes.number,
        }),
        pricePerMeasurementUnit: PropTypes.number,
      }),
      size: PropTypes.string,
    }),
  ),
  country: PropTypes.string,
  businessType: PropTypes.oneOf(Object.values(Verticals)),
  vendorId: PropTypes.string,
  url: PropTypes.string,
  orderId: PropTypes.string,
};

export { ProductsGrid };
