import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  gap: ${(props) => (props.space ? props.theme.space(props.space) : props.theme.space('space-04'))};
`;

export { Container };
