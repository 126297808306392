import React, { memo } from 'react';
import PropTypes from 'prop-types';
import CardContainer from '@pedidosya/web-fenix/molecules/CardContainer';
import ChevronRight from '@pedidosya/web-fenix/icons/ChevronRight';
import Typography from '@pedidosya/web-fenix/atoms/Typography';
import Translation from '@app/components/OrderOffer/OrderOffer.translation';
import { Container } from './ViewAll.style';
import { useIntl } from 'react-intl';

const ViewAll = memo(function ViewAll({ onClick, size }) {
  const { formatMessage } = useIntl();
  return (
    <CardContainer withShadow={false}>
      <Container onClick={onClick}>
        <ChevronRight size={size} />
        <Typography token="font-label-highercontrast-medium" color="text-color-primary">
          {formatMessage(Translation.offer_market_view_all)}
        </Typography>
      </Container>
    </CardContainer>
  );
});

ViewAll.defaultProps = {
  onClick: () => {},
  size: 'medium',
};

ViewAll.propTypes = {
  onClick: PropTypes.func,
  size: PropTypes.oneOf(['small', 'medium']),
};

export { ViewAll };
