import React from 'react';
import styled from 'styled-components';
import Typography from '@pedidosya/web-fenix/atoms/Typography';

const Container = styled.div`
  min-height: ${(props) => (props.isWebview ? `${props.height - 46}px` : 'auto')};
  display: flex;
  align-items: center;
  align-content: center;
  text-align: center;
  justify-content: center;
  width: 100%;
  flex-wrap: nowrap;
  flex-direction: column;
  & > picture {
    display: inline-block;
  }
`;

const Title = styled(Typography)`
  margin-top: ${(props) => props.theme.size('space-06')};
  width: 240px;
  display: inline-block;
`;

const TitleContainer = styled.div`
  text-align: center;
`;

export { Container, Title, TitleContainer };
