import React from 'react';
import { useLinkContext } from '@app/contexts/LinkProvider';
import { Verticals } from '@app/config/verticals';
import { ACTIONS } from '@app/utils/deeplink';
import { Constants } from '@app/config/constants';
import { useTracker } from '@app/contexts/TrackerProvider';

const useProductGridEvents = () => {
  const { redirect } = useLinkContext();
  const { productClick } = useTracker();

  const Listeners = [
    (context) => {
      productClick(Constants.ORIGIN_OS, context?.orderId, context?.productId, context?.vendorId);

      if (context?.businessType === Verticals.GROCERIES) {
        return redirect(ACTIONS.GROCERY, {
          productId: context?.productId,
          vendorId: context?.vendorId,
          url: context?.url,
          path: window.btoa(
            encodeURIComponent(Constants.GROCERY_PATH_DL.replace('{vendorId}', context?.vendorId)),
          ),
        });
      }

      return redirect(ACTIONS.FOOD, {
        productId: context?.productId,
        vendorId: context?.vendorId,
        url: context?.url,
      });
    },
    (context) => {
      return redirect(ACTIONS.SHOPDETAIL, {
        vendorId: context?.vendorId,
        url: context?.url,
      });
    },
  ];

  return Listeners;
};

export { useProductGridEvents };
