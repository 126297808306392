import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import Translations from './OrderOffer.translation';
import { ProductsGrid } from './ProductsGrid';
import { MarketList } from './MarketList';
import { Title, ProductContainer, DeliveryInfoContainer } from './OrderOffer.style';
import { Constants } from '@app/config/constants';
import { useIntl } from 'react-intl';
import { Verticals } from '@app/config/verticals';
import { NotFoundOffer } from '@app/components/NotFoundOffer';
import { DeliveryInfo } from '@app/components/OrderOffer/DeliveryInfo';

const OrderOffer = memo(function OrderOffer({ offers, orderId }) {
  const { formatMessage } = useIntl();

  const products = offers.filter((element) => element.hierarchy === Constants.STORE_SHOW_PRODUCTS);
  const markets = offers.filter(
    (element) => element.hierarchy === Constants.STORE_DONT_SHOW_PRODUCTS,
  );
  /**
   * hasPrincipal
   *
   * @description Function to determinate if there is principal markets with hierarchy 1
   * and its have products. Normally the backend return only 1 vendor with hierarchy 1
   * so, will comprobe that the first element in offers array have products
   */
  const hasPrincipal = useMemo(() => {
    const principal = offers.filter(
      (element) => element.hierarchy === Constants.STORE_SHOW_PRODUCTS,
    );
    return principal?.length > 0 && principal[0]?.products?.length > 0;
  }, [offers]);

  /**
   * hasMarkets
   *
   * @description Function to determinate if there is markets with hierarchy 2
   */
  const hasMarkets = useMemo(() => {
    const secondaryMarkets = offers.filter(
      (element) => element.hierarchy === Constants.STORE_DONT_SHOW_PRODUCTS,
    );
    return secondaryMarkets?.length > 0;
  }, [offers]);

  if (!hasPrincipal && !hasMarkets) {
    return <NotFoundOffer orderId={orderId} />;
  }

  return (
    <>
      {hasPrincipal ? (
        <div>
          <Title
            component="h3"
            token="font-subtitle-highcontrast-sentence-large"
            color="text-color-primary"
            mBottom={4}
            mTop="0"
          >
            {formatMessage(Translations.offer_title)}
          </Title>
          {products.map((element) => {
            return (
              <>
                <DeliveryInfoContainer>
                  <DeliveryInfo
                    country={element?.vendorInformation?.countryCode}
                    amount={element?.vendorInformation?.delivery?.amount}
                    hasPlus={element?.vendorInformation?.delivery?.hasPlus}
                    discountAmount={element?.vendorInformation?.delivery?.discountAmount}
                    time={element?.vendorInformation?.delivery?.time}
                  />
                </DeliveryInfoContainer>
                <ProductContainer key={element.id}>
                  <ProductsGrid
                    products={element?.products}
                    country={element?.vendorInformation?.countryCode}
                    businessType={element?.vendorInformation?.businessType}
                    vendorId={element?.vendorInformation?.id}
                    url={element?.vendorInformation?.url}
                    orderId={orderId}
                  />
                </ProductContainer>
              </>
            );
          })}
        </div>
      ) : (
        <></>
      )}

      {hasMarkets ? (
        <>
          <Title
            component="h3"
            token="font-subtitle-highcontrast-sentence-large"
            mBottom="16"
            mTop="24"
          >
            {formatMessage(Translations.offer_market_title)}
          </Title>
          <MarketList
            markets={markets}
            businessType={markets[0]?.vendorInformation?.businessType}
            url={markets[0]?.vendorInformation?.url}
            orderId={orderId}
          />
        </>
      ) : (
        <></>
      )}
    </>
  );
});

OrderOffer.defaultProps = {
  offers: [],
  orderId: '',
};

OrderOffer.propTypes = {
  offers: PropTypes.arrayOf(
    PropTypes.shape({
      products: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string.isRequired,
          name: PropTypes.string.isRequired,
          description: PropTypes.string,
          image: PropTypes.string,
          raiting: PropTypes.number,
          price: PropTypes.shape({
            finalPrice: PropTypes.number.isRequired,
            originalPrice: PropTypes.number.isRequired,
            pricePerMeasurementUnit: PropTypes.number,
            discount: PropTypes.shape({
              amount: PropTypes.number,
              percentage: PropTypes.number,
            }),
          }),
          size: PropTypes.string,
        }),
      ),
      vendorInformation: PropTypes.shape({
        name: PropTypes.string,
        id: PropTypes.string,
        logo: PropTypes.string,
        countryCode: PropTypes.string,
        businessType: PropTypes.oneOf(Object.values(Verticals)),
        delivery: PropTypes.shape({
          time: PropTypes.string,
          amount: PropTypes.number,
          hasPlus: PropTypes.bool,
          discountAmount: PropTypes.number,
        }),
      }),
    }),
  ),
  orderId: PropTypes.string,
};

export { OrderOffer };
