import React from 'react';
import styled from 'styled-components';
import Typography from '@pedidosya/web-fenix/atoms/Typography';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${(props) => props.theme.space('space-04')};
  padding: ${(props) => props.theme.space('space-06')};
  cursor: pointer;
`;

const VendorName = styled(Typography)`
  margin-bottom: ${(props) => props.theme.space('space-02')};
`;

export { Container, VendorName };
