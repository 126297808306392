import React from 'react';
import Shimmer from '@appscore/web-components/Shimmer';
import Swiper from '@appscore/web-components/Swiper';
import { Container } from '@app/components/Container';
import { SkeletonContainer } from './PageLoading.style';

const PageLoading = () => {
  return (
    <Container data-testid="loading" role="alert" aria-busy="true" pLeft="0" pRight="0" pTop="0">
      <div style={{ marginTop: 14 }}>
        <Shimmer width="180px" height="24px" shape="square" />
      </div>
      <div style={{ marginBottom: 24 }}>
        <Shimmer width="220px" height="16px" shape="square" />
      </div>
      <Swiper>
        <SkeletonContainer>
          <Shimmer width="136px" height="198px" shape="square" />
        </SkeletonContainer>
        <SkeletonContainer>
          <Shimmer width="136px" height="198px" shape="square" />
        </SkeletonContainer>
        <SkeletonContainer>
          <Shimmer width="136px" height="198px" shape="square" />
        </SkeletonContainer>
        <SkeletonContainer>
          <Shimmer width="136px" height="198px" shape="square" />
        </SkeletonContainer>
      </Swiper>
      <div style={{ marginTop: 24, marginBottom: 16 }}>
        <Shimmer width="220px" height="24px" shape="square" />
      </div>
      <Swiper>
        <SkeletonContainer>
          <Shimmer width="284px" height="66px" shape="square" />
        </SkeletonContainer>
        <SkeletonContainer>
          <Shimmer width="284px" height="66px" shape="square" />
        </SkeletonContainer>
        <SkeletonContainer>
          <Shimmer width="284px" height="66px" shape="square" />
        </SkeletonContainer>
        <SkeletonContainer>
          <Shimmer width="284px" height="66px" shape="square" />
        </SkeletonContainer>
      </Swiper>
    </Container>
  );
};

export { PageLoading };
